<script setup lang="ts">
import { PostHogEvents } from '@respell/utils/tracking/types';
import GuestTopbar from '~/components/home/GuestTopbar.vue';
const { $clientPosthog } = useNuxtApp();

definePageMeta({
  auth: {
    unauthenticatedOnly: false,
  },
});

defineOgImageScreenshot({
  delay: 2000,
});

const spellStore = useSpellsStore();
const userStore = useUserStore();
const templateSlug = useRouteParams('slug');

await useAsyncData(
  () => spellStore.loadCompleteSpell(null, templateSlug.value as string),
  { immediate: !!templateSlug.value },
);

const { user } = storeToRefs(userStore);
const { template, spell, liveGraph, testRun } = storeToRefs(spellStore);

useSeoMeta({
  title: () => spell.value?.name as string,
  description: () => spell.value?.description as string,
});

onBeforeMount(async () => {
  testRun.value = undefined;
});

async function useTemplate() {
  if (!user.value) {
    return navigateTo({ name: 'login' });
  }

  await spellStore.duplicateSpell(spell.value?.id as string);

  $clientPosthog?.capture(PostHogEvents.UserUsedTemplate, {
    templateId: template.value?.id,
    userId: user.value?.id,
    email: user.value?.email,
  });
}
</script>
<template>
  <div
    :class="
      templateSlug
        ? 'grow flex flex-col justify-start max-h-screen overflow-hidden'
        : 'contents'
    "
  >
    <GuestTopbar v-if="templateSlug" />
    <div
      class="flex justify-evenly h-full max-h-full w-full overflow-hidden px-36 gap-10"
    >
      <section
        class="flex flex-col max-w-lg py-14 w-full max-h-full overflow-y-scroll gap-2 items-start justify-start"
      >
        <app-spell-icon :spell="spell" size="l" />
        <h1 class="main-title">{{ spell?.name }}</h1>
        <img
          src="/respell-social-logo.png"
          alt="Respell Logo"
          style="width: 5rem"
        />
        <AppMarkdown
          :value="spell?.description"
          max-height="500px"
          class="mt-2"
        />
        <UButton
          icon="i-ph-copy-bold"
          size="xl"
          color="primary"
          variant="solid"
          label="Use Template"
          class="mt-s"
          :ui="{
            rounded: 'rounded-full',
            font: 'font-bold',
            size: { xl: 'text-lg' },
            gap: { xl: 'gap-1.5' },
          }"
          @click="useTemplate"
        />
      </section>
      <section
        v-if="template"
        class="flex flex-col justify-center max-h-full max-w-lg w-full overflow-hidden"
      >
        <div
          class="overflow-auto flex py-12 flex-col w-full gap-5 justify-normal items-center"
        >
          <h3 class="sr-only">Template Preview</h3>
          <SpellFormInput
            :run="template.preview"
            :graph="liveGraph"
            size="sm"
            readonly
            class="border-x border-gray-200 rounded-2xl"
          />
          <div v-if="testRun" class="contents">
            <div class="flex flex-col gap-2 w-full">
              <SpellRunDetails :run="testRun" :graph="liveGraph" size="sm" />
            </div>
            <SpellFormOutput
              :run="testRun"
              :graph="liveGraph"
              size="sm"
              class="border-x border-gray-200 rounded-2xl"
            />
          </div>
          <p v-else class="flex gap-3 body dimmed">
            <UIcon
              name="i-respell-drawn-arrow-up"
              class="text-2xl"
              aria-hidden="true"
            />
            Click here to see how it works
          </p>
        </div>
      </section>
    </div>
  </div>
</template>
